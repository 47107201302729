import React from "react"
import Headerjawn from "../components/headerjawn"
import Layout from "../components/layout"
import Footerjawn from "../components/footerjawn"

import posed from "react-pose"
import { TransitionState } from "gatsby-plugin-transition-link"
import { tween } from "popmotion";


const Box = posed.div({
  hidden: { 
    opacity: [0,1], 
    left: '-100%',
    transition: props => tween({ ...props, duration: 0 })
  },
  visible: { 
    opacity: [1,0], 
    left: '100%',
    transition: props => tween({ ...props, duration: 1000 })
  },
})

export default () => (
  <>
    <Headerjawn/>   
    
    <Layout>
      <section className="work-cc">
      <h2>Work</h2>
        <p>WORKWORKWhat a world.</p>
        <p>Esstnei akhfdio, sdiion sdnfsj snms mc jq iqwwueh ivsdos.
        Posjd, nefusdm cnjas cinth dh wodn a  si a ansnhw aosi dsna spds.
        Lids, shhur fidouh sdh, shiwein ndsh ncopidhurwn nhsjwoa nbbdsj ska
        ksiid sndj no a nsjs anjjs anbd akjhsin non snwnbsdb hs.</p>
      </section>
    </Layout>

    <Footerjawn/>

    <TransitionState>
        {({ transitionStatus }) => {
            return (
                <Box
                  className="box"
                  pose={
                    ['entering', 'entered'].includes(transitionStatus)
                      ? 'hidden'
                      : 'visible'
                  }
                />
            )
        }}
    </TransitionState>
    <style
          dangerouslySetInnerHTML={{
            __html: `
            .box {
                background: #f00d42;
            }
        `,
          }}
    /> 
    
  </>
)